import React, {createContext, useContext, useReducer} from "react";
import {useTranslation} from "react-i18next";
import {WorkerStatusSnackBar} from "./WorkerStatusSnackBar";

export const CREATE_OBJECTS_START = 'damsObjectCreatorWorkerContext/createObjectsStart';

const DamsObjectCreatorWorkerState = createContext(undefined);
const DamsObjectCreatorWorkerDispatchContext = createContext(undefined);
const DamsObjectCreatorWorkerTranslationContext = createContext(undefined);

const initialState = {
    jobs: []
};

const damsObjectCreatorWorkerReducer = (state, action) => {
    switch (action.type) {
        case CREATE_OBJECTS_START:
            return {};
            break;
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};

export const DamsObjectCreatorWorkerProvider = ({children}) => {
    const [state, dispatch] = useReducer(
        damsObjectCreatorWorkerReducer,
        initialState,
        undefined
    );
    const {t} = useTranslation("dams.fileupload", {useSuspense: false});

    return (
        <DamsObjectCreatorWorkerTranslationContext.Provider value={t}>
            <DamsObjectCreatorWorkerState.Provider value={state}>
                <DamsObjectCreatorWorkerDispatchContext.Provider value={dispatch}>
                    {/*<FileDownloadsWorkerListener/>*/}
                    <WorkerStatusSnackBar/>
                    {children}
                </DamsObjectCreatorWorkerDispatchContext.Provider>
            </DamsObjectCreatorWorkerState.Provider>
        </DamsObjectCreatorWorkerTranslationContext.Provider>
    );
};

export const useDamsObjectCreatorWorkerState = () => {
    const context = useContext(DamsObjectCreatorWorkerState);
    if (undefined === context) {
        throw new Error(
            "useDamsObjectCreatorWorkerState must be used within an DamsObjectWorkerProvider."
        );
    } else {
        return context;
    }
};

export const useDamsObjectCreatorWorkerDispatch = () => {
    const context = useContext(DamsObjectCreatorWorkerDispatchContext);
    if (undefined === context) {
        throw new Error(
            "useDamsObjectCreatorWorkerDispatch must be used within an DamsObjectCreatorWorkerProvider."
        );
    } else {
        return context;
    }
};

export const useDamsObjectCreatorWorkerTranslation = () => {
    const context = useContext(DamsObjectCreatorWorkerTranslationContext);
    if (undefined === context) {
        throw new Error(
            "useDamsObjectCreatorWorkerTranslation must be used within an DamsObjectCreatorWorkerProvider"
        );
    } else {
        return context;
    }
};
