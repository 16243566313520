import {Paper, Popper, Typography} from "@mui/material";
import {HIDE_OPERATIONS_SUB_POPPER, useAppDispatch, useAppState} from "../app/AppContext";
import Box from "@mui/material/Box";

const FileUploadPopper = () => {
    const {showOperationsSubPopper, operationsSubPopperContent} = useAppState();
    const appDispatch = useAppDispatch();

    let viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)

    /**
     * Method used to create the bounding client rects for the virtual object, used for positioning the popper.
     * @returns {function(): {top: number, left: number, width: number, height: number}}
     */
    function generateGetBoundingClientRect() {
        return () => ({
            width: 0,
            height: 0,
            top: 120,
            left: viewportWidth
        });
    }

    /**
     * Hides the popper by dispatching an action to hide the operations sub popper.
     *
     * @return {void}
     */
    const hidePopper = () => {
        appDispatch({
            type: HIDE_OPERATIONS_SUB_POPPER,
        });
    };

    /**
     * VirtualElement, used to position the popper at the correct x- and y position.
     * @type {{getBoundingClientRect: (function(): {top: number, left: number, width: number, height: number})}}
     */
    const virtualPopperElement = {
        getBoundingClientRect: generateGetBoundingClientRect(),
    };

    return <Popper id={"operations-popper-id"}
                   open={showOperationsSubPopper}
                   anchorEl={virtualPopperElement}
                   onClick={hidePopper}
                   sx={{
                       zIndex: 9999,
                       cursor: 'pointer',
                   }}>
        <Paper elevation={2} sx={{padding: 2}}>
            <Typography variant={"body2"}>Opplasting</Typography>
            <Box data-testid={"FileUploadPopperDynamicContent"}>
                {operationsSubPopperContent}
            </Box>
        </Paper>
    </Popper>;
};

FileUploadPopper.propTypes = {};

export {FileUploadPopper};