import {CREATE_OBJECTS_START} from "../workerstatus/damsObjectCreatorWorkerContext";

export const kickOffDamsObjectCreatorWorker = ({
                                                   workerConfig,
                                                   jobName,
                                                   collectionId,
                                                   files,
                                                   damsObjectCreatorWorkerDispatch
                                               }) => {
    const job = {
        jobId: crypto.randomUUID(),
        name: jobName,
        active: true,
        complete: false,
        collectionId: collectionId,
        files: files,
        stages: [],
    };

    // Signal that we're initiating a new job.
    damsObjectCreatorWorkerDispatch({
        type: CREATE_OBJECTS_START,
        job: job
    });

    // Kick off web worker.
    window._damsObjectCreatorWorkerInstance.postMessage(JSON.stringify({
        config: workerConfig,
        action: 'start',
        channel: 'worker',
        job: job
    }));
};