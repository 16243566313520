import {
    ADD_FILES,
    ADD_SAVED,
    SET_ACTIVE_STEP,
    SHOW_FILEUPLOAD_DIALOG,
    useFileUploadDispatch,
    useFileUploadState
} from "../damsfileupload/fileUploadContext";
import React, {useCallback, useEffect} from "react";
import {
    HIDE_OPERATIONS_POPPER,
    HIDE_OPERATIONS_SUB_POPPER,
    SHOW_OPERATIONS_POPPER,
    SHOW_OPERATIONS_SUB_POPPER,
    UPDATE_OPERATIONS_SUB_POPPER,
    useAppDispatch,
    useAppState
} from "../app/AppContext";
import {Button, LinearProgress, Typography} from "@mui/material";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import MoreVertIcon from "@mui/icons-material/MoreVert";


/**
 * React component that listens to messages from the DamsObjectCreatorWorker and updates the UI accordingly.
 *
 * @return {JSX.Element} The UI component that displays the number of ongoing jobs when the user is in the process of uploading files.
 */
export const DamsObjectCreatorWorkerListener = () => {
    const {numberOfActiveOperations} = useAppState();
    const {activeStep, extractMetadata} = useFileUploadState();

    const appDispatch = useAppDispatch();
    const fileUploadDispatch = useFileUploadDispatch();


    /**
     * Function to show the operations popper, that displays the number of ongoing jobs,
     * when the user is in the process of uploading files.
     */
    const showOperationsPopper = () => {
        appDispatch({
            type: SHOW_OPERATIONS_POPPER,
            numberOfActiveOperations: numberOfActiveOperations + 1,
            callbackMethod: () => {
                appDispatch({
                    type: SHOW_OPERATIONS_SUB_POPPER
                });
            }
        });
    };

    /**
     * Function to hide the operations popper, that displays the number of ongoing jobs,
     */
    const hideOperationsPopper = () => {
        appDispatch({
            type: HIDE_OPERATIONS_POPPER
        });
    };

    /**
     * Function to hide the operations popper, that displays the info about the job.
     * when the user is in the process of uploading files.
     */
    const hideOperationsSubPopper = () => {
        appDispatch({
            type: HIDE_OPERATIONS_SUB_POPPER,
        });
    };

    /**
     * Updates the sub content of the operation popper with the given data.
     *
     * @param {Object} data - The data object containing the following properties:
     *   - percentage {number}: The percentage of completion.
     *   - label {string}: The label of the operation.
     *   - subLabel {string}: The sublabel of the operation.
     *   - numFiles {number}: The number of files.
     *   - actionButton {Object}: The action button object with the following properties:
     *     - callback {Function}: The callback function to be executed when the button is clicked.
     *     - label {string}: The label of the button.
     * @return {void}
     */
    const updateOperationPopperSubContent = (data) => {
        const {percentage, label, subLabel, numFiles, actionButton} = data;
        appDispatch({
            type: UPDATE_OPERATIONS_SUB_POPPER,
            content: <Box>
                <Stack direction={"column"} alignContent={"center"}
                       justifyContent={"center"}>
                    <Typography mt={2} mb={1} variant={"h6"} sx={{fontWeight: 'bold'}}>
                        {/*XXX filer behandles i bakgrunnen*/}
                        {numFiles} {label}
                    </Typography>
                    <LinearProgress variant={"determinate"} value={percentage} color={"secondary"}/>
                    <Typography mt={2} variant={"body2"}>
                        {/*Du får en ny beskjed når behandlingen er ferdig.*/}
                        {subLabel}
                    </Typography>
                </Stack>
                <Box sx={{width: '100%', marginTop: '16px', display: 'flex'}}>
                    {actionButton && <Button onClick={actionButton?.callback}>{actionButton?.label}</Button>}
                    <Box flexGrow={1}/>
                    {!actionButton && <MoreVertIcon/>}
                </Box>
            </Box>
        });
    };

    /**
     * Retrieves the current stage based on the provided data and job.
     *
     * @param {Object} data - The data object containing the current stage ID.
     * @param {Object} job - The job object containing a list of stages.
     * @return {Object | undefined} The current stage object or undefined if not found.
     */
    const getCurrentStage = (data, job) => {
        const currentStageId = data.stageId;
        return job.stages.find(s => s.id === currentStageId);
    }

    /**
     * Shows the edit metadata step, by adding the uploaded files to the file upload state,
     * and then setting the active step to the edit metadata step.
     * @param job
     */
    const showEditMetadataStep = job => {
        fileUploadDispatch({
            type: ADD_FILES,
            uploadedFiles: job.successfulFiles
        });
        fileUploadDispatch({
            type: ADD_SAVED,
            documents: job.successfulFiles
        });
        fileUploadDispatch({
            type: SET_ACTIVE_STEP,
            activeStep: activeStep + 1
        });
        fileUploadDispatch({
            type: SHOW_FILEUPLOAD_DIALOG
        });
    };

    /**
     * Checks if the provided message is valid.
     *
     * @param {Object} event - The message event object.
     * @return {boolean} True if the message is valid, false otherwise.
     */
    const isValidMessage = event => {
        if (!event.data) {
            return false;
        }
        const {channel, action} = event.data;
        return !(!channel || channel !== 'client' || !action || action === '');
    };

    /**
     * Updates the operation popper sub content to show the choice to edit metadata.
     *
     * @param {number} percentage - The percentage of completion.
     * @param {Object} job - The job object.
     * @return {void}
     */
    const showEditMetadataChoice = (percentage, job) => {
        updateOperationPopperSubContent({
            percentage: percentage,
            numFiles: job.files.length,
            label: 'er klare for redigering',
            subLabel: 'Du må redigere metadata i filene for å fullføre opplastingen.',
            files: job.successfulFiles,
            actionButton: {
                callback: () => {
                    hideOperationsSubPopper();
                    hideOperationsPopper();
                    showEditMetadataStep(job);
                },
                label: 'Se igjennom'
            }
        });
        appDispatch({type: SHOW_OPERATIONS_SUB_POPPER});
    };


    /**
     * Sends a message to the damsObjectCreatorWorkerInstance to initiate metadata extraction.
     *
     * @param {Object} config - The configuration object.
     * @param {Object} job - The job object.
     * @return {void}
     */
    const kickOffMetadataExtraction = (config, job) => {
        window._damsObjectCreatorWorkerInstance.postMessage(JSON.stringify({
            config: config,
            action: 'extractMetadata',
            channel: 'worker',
            job: job,
        }));
    };

    /**
     * Method used to parse messages received from the DAMS object creator web worker.
     * @type {(function(*): void)|*}
     */
    const workerMessageParser = useCallback(async (event) => {
        if (!isValidMessage(event)) {
            return;
        }

        const data = event.data;
        const {action, job, config} = data;

        console.debug('workerMessageParser - event: ', event);
        console.debug('workerMessageParser - job: ', job);
        console.debug('workerMessageParser - config: ', config);

        if (action === 'addToQueue') {
            // Add the job to the job queue on the client.
            // TODO: add support for multiple jobs!
        }

        if (action === 'stageUpdate') {
            const currentStage = getCurrentStage(data, job);
            const {name, percentage} = currentStage;

            console.debug('workerMessageParser.currentStage: ', currentStage);

            if (name === 'creatingDamsObjects') {
                // Consider this the first stage for now, and show the circular progress,
                // indicating that a job is being run in the background.
                showOperationsPopper();
                updateOperationPopperSubContent({
                    percentage: 0,
                    numFiles: job.files.length,
                    label: 'Prosesserer',
                    subLabel: 'Oppretter DAMS objekter',
                });
            } else if (name === 'savingDamsObjects') {
                if (percentage === 100) {
                    if (job.successfulFiles) {
                        updateOperationPopperSubContent({
                            percentage: percentage,
                            numFiles: job.successfulFiles.length,
                            label: 'filer lagret i DAMS',
                            subLabel: 'Filer lastet opp, og DAMS objekter opprettet.'
                        });

                        // Start getting object status
                        window._damsObjectCreatorWorkerInstance.postMessage(JSON.stringify({
                            config: config,
                            action: 'getobjectstatus',
                            channel: 'worker',
                            job: job,
                        }));
                    }
                } else if (percentage > 0 && percentage < 100) {
                    updateOperationPopperSubContent({
                        percentage: percentage,
                        numFiles: job.files.length,
                        label: 'filer behandles i bakgrunnen',
                        subLabel: 'Du får en ny beskjed når behandlingen er ferdig.'
                    });
                }
            } else if (name === 'gettingObjectStatus') {
                if (percentage === 100) {
                    if (job.successfulFiles.find(f => f.documentType === 'StillImage')) {
                        // If one or more of the uploaded files is an image-file, get the thumbnails for all of them.
                        window._damsObjectCreatorWorkerInstance.postMessage(JSON.stringify({
                            config: config,
                            action: 'addingThumbnails',
                            channel: 'worker',
                            job: job,
                        }));
                    } else if (extractMetadata) {
                        // Go directly to metadata-extraction, if specified by the user.
                        kickOffMetadataExtraction(config, job);
                    } else {
                        // Show proceed to metadata editing step.
                        showEditMetadataChoice(percentage, job);
                    }
                } else if (percentage > 0 && percentage < 100) {
                    updateOperationPopperSubContent({
                        percentage: percentage,
                        numFiles: job.files.length,
                        label: 'Konverterer filer',
                        subLabel: 'Filer konverteres. Dette tar noe tid..',
                        files: job.successfulFiles,
                    });
                }
            } else if (name === 'addingThumbnails') {
                if (percentage === 100) {
                    if (extractMetadata) {
                        // If the user has chosen to get metadata extracted from the files,
                        // run this procedure.
                        kickOffMetadataExtraction(config, job);
                    } else {
                        // If the user has not chosen to get metadata extracted from the files,
                        // offer him/her to proceed to metadata editing directly.
                        showEditMetadataChoice(percentage, job);
                    }
                }
            } else if (name === 'mappingMetadata') {
                // Extracting, and mapping metadata for the uploaded files.
                updateOperationPopperSubContent({
                    percentage: percentage,
                    numFiles: job.files.length,
                    label: 'Henter metadata',
                    subLabel: 'Henter metadata. Dette kan ta en liten stund. Vennligst vent.',
                    files: job.successfulFiles
                });
            } else if (name === 'updatingMappedDamsObjects') {
                if (percentage === 100) {
                    // Metadata mapping complete, offer the user to edit metadata.
                    showEditMetadataChoice(percentage, job)
                } else {
                    // Saving documents with updated metadata.
                    updateOperationPopperSubContent({
                        percentage: percentage,
                        numFiles: job.files.length,
                        label: 'Lagrer metadata',
                        subLabel: 'Lagrer metadata. Dette kan ta en liten stund. Vennligst vent.',
                        files: job.successfulFiles
                    });
                }
            }
        }
    });

    useEffect(() => {
        window._damsObjectCreatorWorkerInstance.onmessage = workerMessageParser;
    }, [workerMessageParser]);
};