import React, {useEffect, useState} from "react";
import IconButton from "@mui/material/IconButton";
import GetAppIcon from "@mui/icons-material/GetApp";
import {useSnackbarTranslation,} from "../snackbar/SnackbarContext";
import {useAuthsState} from "../auths/authsContext";
import {useMyMuseums} from "../museum/useMyMuseums";
import Typography from "@mui/material/Typography";
import {useProjectState} from "../project/projectContext";
import {useDmsStatusState} from "../dmsstatus/DmsStatusContext";
import {kickOffDownloadWorker} from "../webworkers/kickOffDownloadWorker";
import {useDownloadWorkerDispatch} from "../workerstatus/workerStatusContext";
import {getWorkerConfigBase} from "../webworkers/getWorkerConfigBase";
import {DownloadOptionsDialog} from "./DownloadOptionsDialog";
import {SetImageSize} from "../multiple-files-download/SetImageSize";
import {getDmsIdFromModel} from "../utility";
import PropTypes from "prop-types";
import {SetDownloadFormat} from "../multiple-files-download/SetDownloadFormat";

/**
 * Renders a download button component based on the document type of the given model.
 *
 * @param {Object} model - The model object containing information about the document.
 * @param {boolean} [disabled=false] - Indicates whether the download button is disabled.
 * @param {boolean} [showLabel=false] - Indicates whether the download button should show a label.
 * @return {JSX.Element} The download button component based on the document type.
 */
const ButtonDownload = ({
                            model,
                            disabled = false,
                            showLabel = false,
                        }) => {
    const t = useSnackbarTranslation();

    const {hasArcheologyModule} = useProjectState();
    const [disableButton, setDisableButton] = useState(false);
    const {museumCollections} = useAuthsState();
    const {dmsStatus} = useDmsStatusState();

    const [customImageSize, setCustomImageSize] = useState({
        width: 800,
        height: 600,
        selected: true
    });

    const [openImageSizeDialog, setOpenImageSizeDialog] = useState(false);
    const [openFormatDialog, setOpenFormatDialog] = useState(false);

    const [audioFormat, setAudioFormat] = useState("original");
    const [videoFormat, setVideoFormat] = useState("original");

    const downloadWorkerDispatch = useDownloadWorkerDispatch();
    const myMuseums = useMyMuseums({});

    const {collectionId, documentType} = model;
    const dmsId = getDmsIdFromModel(model);

    // Set the supported document types.
    let documentTypes = ['Video', 'StillImage', 'Misc', 'Audio', 'Modell'];
    if (hasArcheologyModule) {
        documentTypes = [...documentTypes, ...['Dokument', 'Tabell', 'Geodata']];
    }

    const getJobName = () => {
        const {title} = model;
        return title.substring(0, title.lastIndexOf('.'))
    };

    // Web worker job config, base-object.
    const apiGateway = window._env_.REACT_APP_DAMS_ADMIN_API;
    const webWorkerJob = {
        workerConfig: getWorkerConfigBase(apiGateway),
        jobName: getJobName(),
        documents: [model],
        downloadWorkerDispatch: downloadWorkerDispatch,
    };

    /**
     * Handles "misc" download.
     */
    const handleMiscDownload = () => {
        kickOffDownloadWorker({
            ...webWorkerJob,
        });
    };

    /**
     * Handles image download.
     */
    const handleImageDownload = () => {
        kickOffDownloadWorker({
            ...webWorkerJob,
            customImageSize: customImageSize
        });
    };

    /**
     * Handle audio download.
     */
    const handleAudioDownload = () => {
        kickOffDownloadWorker({
            ...webWorkerJob,
            audioFormat: audioFormat
        });
    };

    /**
     * Handle video download.
     */
    const handleVideoDownload = () => {
        kickOffDownloadWorker({
            ...webWorkerJob,
            videoFormat: videoFormat
        });
    };

    /**
     * Hook used to determine whether the user has access to download files or not.
     */
    useEffect(() => {
        if (myMuseums.length > 0 && dmsStatus) {
            const museumId = museumCollections.find(
                (mc) => mc.collectionId === collectionId
            )?.museumId;

            if (!museumId) {
                return;
            }

            const museum = myMuseums.find((m) => m.id === museumId);
            if (dmsStatus?.missingConfigs?.includes(museum['ekultur_id'])) {
                setDisableButton(true);
            }
            if (dmsStatus.errorCount > 0) {
                setDisableButton(true);
            }
        }
    }, [dmsStatus, myMuseums, collectionId, museumCollections]);

    /**
     * Opens the option dialog for audio-, video- and image files.
     * @param dialogType
     */
    const openOptionDialogClick = dialogType => {
        if (dialogType === 'audio' || dialogType === 'video') {
            setOpenFormatDialog(true);
        } else if (dialogType === 'image') {
            setOpenImageSizeDialog(true);
        }
    }

    /**
     * Closes the option dialog for image-, audio- and video files,
     * and triggers their respective download handlers.
     * @param dialogType
     */
    const closeDialogClick = dialogType => {
        switch (dialogType) {
            case 'video':
                setOpenFormatDialog(false);
                handleVideoDownload();
                break;
            case 'audio':
                setOpenFormatDialog(false);
                handleAudioDownload();
                break;
            case 'image':
                setOpenImageSizeDialog(false);
                handleImageDownload();
                break;
            default:
                break;
        }
    };

    /**
     * Returns the download button used for all object types but StillImage.
     * @returns {JSX.Element}
     */
    const getDownloadButton = (onClickCallback) => {
        return <>
            <IconButton
                color={"secondary"}
                onClick={onClickCallback}
                disabled={
                    disabled ||
                    disableButton ||
                    null === collectionId ||
                    null === dmsId ||
                    !documentTypes.includes(documentType) ||
                    dmsStatus.errorCount > 0
                }
                size={showLabel ? "small" : "large"}
            >
                <GetAppIcon/>
                {showLabel && (
                    <Typography variant={"caption"} sx={{marginLeft: 1}}>
                        {t("btnDownload", "Last ned")}
                    </Typography>
                )}
            </IconButton>
        </>;
    };

    /**
     * Returns the download button used for StillImage objects.
     * @returns {JSX.Element}
     */
    const getImageDownloadButton = () => {
        return <>
            {getDownloadButton(() => openOptionDialogClick('image'))}
            <DownloadOptionsDialog
                open={openImageSizeDialog}
                title={t('setImageSize', 'Velg bildestørrelse')}
                content={
                    <SetImageSize
                        multiple={false}
                        sizeCallback={
                            (size) => {
                                setCustomImageSize(size);
                            }
                        }
                    />
                }
                cancelHandler={() => setOpenImageSizeDialog(false)}
                closeHandler={() => closeDialogClick('image')}
            />
        </>
    };

    /**
     * Get the download button for audio or video based on the button type.
     *
     * @param {string} buttonType - The type of button ('video' or 'audio')
     * @return {JSX.Element} The JSX element containing the download button
     */
    const getAudioVideoDownloadButton = (buttonType) => {
        const title = buttonType === 'video'
            ? t('setVideoFormat', 'Velg video-format') : t('setAudioFormat', 'Velg lydformat');

        const callback = buttonType === 'video'
            ? (chosenFormat => setVideoFormat(chosenFormat))
            : (chosenFormat => setAudioFormat(chosenFormat));

        const objectType = buttonType === 'video' ? 'video' : 'audio';
        const cancelHandler = () => setOpenFormatDialog(false);

        const closeHandler = buttonType === 'video'
            ? () => closeDialogClick('video')
            : () => closeDialogClick('audio');

        return <>
            {getDownloadButton(() => openOptionDialogClick('video'))}
            <DownloadOptionsDialog
                open={openFormatDialog}
                title={title}
                content={
                    <SetDownloadFormat
                        multiple={false}
                        formatCallback={callback}
                        objectType={objectType}/>
                }
                cancelHandler={cancelHandler}
                closeHandler={closeHandler}
            />
        </>;
    };

    if (documentType === 'Video') {
        return getAudioVideoDownloadButton('video');
    } else if (documentType === 'Audio') {
        return getAudioVideoDownloadButton('audio');
    } else if (documentType === 'StillImage') {
        return getImageDownloadButton();
    } else {
        return getDownloadButton(handleMiscDownload);
    }
};

ButtonDownload.propTypes = {
    "model": PropTypes.object.isRequired,
    "disabled": PropTypes.bool,
    "showLabel": PropTypes.bool
}

export {ButtonDownload};