import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {EXTRACT_METADATA, useFileUploadDispatch} from "./fileUploadContext";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import React from "react";

/**
 * Renders a component for extracting metadata from uploaded files.
 *
 * @return {JSX.Element} The rendered component.
 */
export const StepExtractMetadata = () => {

    const fileUploadDispatch = useFileUploadDispatch();

    const [checked, setChecked] = React.useState(false);

    const key = 'checkbox-extract-metadata';

    const containerStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        maxWidth: '480px'
    };

    const infoTextStyle = {
        marginBottom: '16px',
        marginTop: '16px'
    };

    const checkboxStyle = {
        padding: '8px',
        backgroundColor: '#eee',
        width: '100%',
        borderRadius: '3px'
    };

    /**
     * Handles the click event on the checkbox.
     *
     * @param {Event} event - The click event object.
     * @return {void} This function does not return a value.
     */
    const handleCheckboxClick = (event) => {
        const checkedState = event.target['checked'];
        setChecked(checkedState);
        fileUploadDispatch({
            type: EXTRACT_METADATA,
            checked: checkedState
        });
    };

    return <Box sx={containerStyle}>
        <Box>
            <Typography variant={"h6"}>
                Skal vi hente metadata fra filene?
            </Typography>
            <Typography variant={"body1"} sx={infoTextStyle}>
                Ønsker du at DAMS skal hente ut og bruke metadata fra de opplastede filene? Kun data som kan kartlegges
                mot
                felter i DAMS vil bli brukt.
            </Typography>

            <FormControlLabel
                sx={checkboxStyle}
                key={key}
                control={<Checkbox
                    checked={checked}
                    name={key}
                    onClick={handleCheckboxClick}/>}
                label={"Ja, hent metadata fra filene"}
            />
        </Box>
    </Box>;
};