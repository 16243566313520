import React, {useEffect, useRef, useState} from "react";
import {Box, Toolbar} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {OperationsPopper} from "../damsfileupload/OperationsPopper";
import {FileUploadPopper} from "../damsfileupload/FileUploadPopper";

const useStyles = makeStyles(() => ({
    root: {
        width: "100dvw",
        height: "100dvh",
        display: "flex",
        flexDirection: "column",
        alignItems: 'stretch',
        justifyContent: 'stretch',
    },
    container: {
        overflow: 'hidden', // Layout changed to have separate overflow scrollbars in results and filters
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'stretch',
        flexGrow: '1',
    },
}));

export const AppContent = ({children}) => {
    const toolbarRef = useRef();
    const [toolbarHeight, setToolbarHeight] = useState(64);

    const classes = useStyles({
        toolbarHeight: toolbarHeight,
    });

    useEffect(() => {
        if (toolbarRef.current) {
            setToolbarHeight(toolbarRef.current['clientHeight']);
        }
    }, [setToolbarHeight]);

    return (
        <div className={classes.root}>
            <Toolbar ref={toolbarRef}/>
            <Box className={classes.container}>
                <OperationsPopper/>
                <FileUploadPopper/>
                {children}
            </Box>
        </div>
    );
};
