import {Popper} from "@mui/material";
import {useAppState} from "../app/AppContext";
import InboxIcon from '@mui/icons-material/Inbox';
import Badge from "@mui/material/Badge";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

/**
 * Popper used to display the number of ongoing operations.
 * @returns {JSX.Element}
 * @constructor
 */
export const OperationsPopper = () => {
    const {
        showOperationsPopper,
        operationsPopperCallback,
        numberOfActiveOperations
    } = useAppState();

    let viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)

    /**
     * Method used to create the bounding client rects for the virtual object, used for positioning the popper.
     * @returns {function(): {top: number, left: number, width: number, height: number}}
     */
    function generateGetBoundingClientRect() {
        return () => ({
            width: 0,
            height: 0,
            top: 64,
            left: viewportWidth - 100
        });
    }

    /**
     * VirtualElement, used to position the popper at the correct x- and y position.
     * @type {{getBoundingClientRect: (function(): {top: number, left: number, width: number, height: number})}}
     */
    const virtualElement = {
        getBoundingClientRect: generateGetBoundingClientRect(),
    };

    return <Popper id={"operations-popper-id"}
                   open={showOperationsPopper}
                   anchorEl={virtualElement}
                   onClick={operationsPopperCallback ? operationsPopperCallback : void (0)}
                   sx={{
                       zIndex: 9999,
                       paddingTop: '16px',
                       paddingBottom: '8px',
                       paddingLeft: '16px',
                       paddingRight: '16px',
                       border: 'solid 1px #bbb',
                       backgroundColor: '#f3f3f3',
                       color: '#333',
                       cursor: 'pointer'
                   }}>
        <Box sx={{
            display: 'flex',
            flexDirection: 'row'
        }}>
            <Badge badgeContent={numberOfActiveOperations} color="secondary">
                <InboxIcon/>
            </Badge>
            <Typography sx={{marginLeft: '16px'}}>
                Operasjoner
            </Typography>
        </Box>
        <LinearProgress variant={"indeterminate"} sx={{width: '100%', marginTop: '4px'}} color={"secondary"}/>
    </Popper>;
};
