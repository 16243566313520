import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import React from "react";
import {
    TOGGLE_OPEN_CONFIRM_DIALOG,
    useFileUploadDispatch,
    useFileUploadState,
    useFileUploadTranslation
} from "./fileUploadContext";

/**
 * Renders the action buttons for the upload stepper component.
 *
 * @param {Object} props - The component props.
 * @param {number} props.numSteps - The total number of steps in the upload process.
 * @param {Function} props.nextCallback - The callback function to be called when the next step is clicked.
 * @return {JSX.Element} The action buttons component.
 */
export const UploadStepperActionButtons = ({numSteps, nextCallback}) => {
    const t = useFileUploadTranslation();
    const {projectId, activeStep, uploadedFiles} = useFileUploadState();
    const fileUploadDispatch = useFileUploadDispatch();

    const showCancelButton = () => (activeStep === 0 && uploadedFiles.length === 0);
    const showEndButton = () => (projectId === -1 && activeStep < (numSteps - 1));

    /**
     * Displays the dialog that allows the user to confirm whether to cancel the wizard or not.
     */
    const handleCancel = () => {
        fileUploadDispatch({
            type: TOGGLE_OPEN_CONFIRM_DIALOG
        });
    };

    /**
     * Button that allows the user to cancel/terminate the wizard, before any files have been uploaded.
     */
    const getCancelButton = () => {
        if (!showCancelButton()) {
            return;
        }
        return <Button onClick={handleCancel}
                       sx={{marginRight: '8px'}}
                       color={"secondary"}>
            {t('btnCancel', 'Avbryt')}
        </Button>;
    };

    /**
     * Button that allows the user to end the wizard when the files have already been uploaded.
     */
    const getEndButton = () => {
        if (!showEndButton()) {
            return;
        }
        return <Button onClick={handleCancel}
                       variant={"outlined"}
                       disabled={activeStep === 0}
                       sx={{marginRight: '8px'}}
                       color={"secondary"}>
            {t('uploadStepperActionButtonBtnCancel', 'Avslutt')}
        </Button>;
    };

    const getFinishWizardButton = () => {
        const buttonText = activeStep === numSteps - 1
            ? t('uploadStepperActionButtonBtnCancel', 'Avslutt') : t('uploadStepperActionButtonBtnContinue', 'Fortsett');
        return <Button onClick={nextCallback} variant={"contained"}
                       disabled={activeStep > 0 && uploadedFiles.length === 0}
                       color={"secondary"}>
            {buttonText}
        </Button>;
    }

    return <Stack direction={"row"} justifyContent={'flex-end'}>
        {getCancelButton()}
        {getEndButton()}
        {getFinishWizardButton()}
    </Stack>;
}