import React from "react";
import {useMetaMappingTranslation} from "./MetaMappingContext";
import {useTheme} from "@emotion/react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Hidden from "@mui/material/Hidden";
import Tab from "@mui/material/Tab";
import {TabContext, TabList} from "@mui/lab";
import {useMetadataConfig} from "./useMetadataConfig";
import {useProjectState} from "../project/projectContext";

export const MappingTypeTabs = ({
                                    activeType,
                                    onChangeType,
                                    show,
                                    children,
                                }) => {
    const {hasArcheologyModule} = useProjectState();
    const [getMetadataConfig] = useMetadataConfig(hasArcheologyModule);
    const theme = useTheme();
    const t = useMetaMappingTranslation();
    const metadataConfig = getMetadataConfig();

    /**
     * Helper method used to translate the tab title, based on the config. settings.
     * @param k str Tab config. key.
     * @returns str
     */
    const getTabTitle = (k) => {
        const def = metadataConfig[k]["tabTitle"];
        const parts = def.split("#");
        return t(parts[0].toString(), parts[1].toString());
    };

    return (
        <Hidden xsUp={!show}>
            <Box
                sx={{
                    flexGrow: 1,
                    backgroundColor: theme.palette.background.paper,
                    marginTop: theme.spacing(4),
                }}
            >
                <TabContext value={activeType}>
                    <AppBar position={"static"} color={"secondary"}>
                        <TabList onChange={(_event, newValue) => onChangeType(newValue)}>
                            {Object.keys(metadataConfig).map((k) => (
                                <Tab label={getTabTitle(k)} value={k} key={k}/>
                            ))}
                        </TabList>
                    </AppBar>
                    {children}
                </TabContext>
            </Box>
        </Hidden>
    );
};
