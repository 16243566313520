import Uppy from '@uppy/core';
import {Dashboard} from '@uppy/react';
import {DmsUppyUpload} from '@ekultur/dms-uppy-upload';
import React, {useEffect, useState} from "react";
import {useUppyTranslations} from "./useUppyTranslations";
import Webcam from '@uppy/webcam';

import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import '@uppy/webcam/dist/style.min.css';
import {ADD_MESSAGE, useSnackbarDispatch} from "../snackbar/SnackbarContext";
import {ADD_FAILED, useFileUploadDispatch, useFileUploadState, useFileUploadTranslation} from "./fileUploadContext";
import {getMimeTypeFromFilename, getObjectTypeFromFilename} from "../damsFileObjectDefinitions";
import Box from "@mui/material/Box";
import {clientLog} from "../clientLog";
import {getXQuoteMode} from "../app/damsFetch";
import {getWorkerConfigBase} from "../webworkers/getWorkerConfigBase";
import {useDamsObjectCreatorWorkerDispatch} from "../workerstatus/damsObjectCreatorWorkerContext";
import {kickOffDamsObjectCreatorWorker} from "../webworkers/kickOffDamsObjectCreatorWorker";
import {DialogFilesInBackground} from "./DialogFilesInBackground";

const componentName = 'StepFileUpload';

/**
 * Asynchronously retrieves the maximum resolution of the user's webcam.
 *
 * @return {Promise<{width: number, height: number}>} A promise that resolves to an object
 * containing the maximum width and height of the webcam.
 * @throws {Error} If no webcam is found, or is not accessible.
 */
const getMaxResolution = async () => {
    try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(device => device.kind === 'videoinput');

        const constraints = {
            video: {
                deviceId: videoDevices[0].deviceId
            }
        };

        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        const track = stream.getVideoTracks()[0];
        const capabilities = track.getCapabilities();
        const settings = track.getSettings();

        track.stop();

        const maxWidth = capabilities.width?.max || settings.width;
        const maxHeight = capabilities.height?.max || settings.height;

        clientLog('info', `max camera resolution: ${maxWidth}x${maxHeight}`, 'setfileupload');

        return {width: maxWidth, height: maxHeight};
    } catch (error) {
        clientLog('error', error.message, 'setfileupload');
        clientLog('warn',
            'failed to retrieve camera resolution, using 1920x1080',
            'setfileupload');
        return {width: 1920, height: 1080};
    }
};

/**
 * Handler triggered before upload, in order to provide a valid mime type for all files.
 * (The browser does not always resolve the correct mime-type.)
 * @param files
 */
const onBeforeUploadHandler = files => {
    /*
       {
            "source": "react:Dashboard",
            "id": "uppy-2cylinderengine/glb-1e-application/octet-stream-1838084-1695291811517",
            "name": "2CylinderEngine.glb",
            "extension": "glb",
            "meta": {
                "relativePath": null,
                "name": "2CylinderEngine.glb",
                "type": "application/octet-stream"
            },
            "type": "application/octet-stream",
            "data": {},
            "progress": {
                "percentage": 0,
                "bytesUploaded": 0,
                "bytesTotal": 1838084,
                "uploadComplete": false,
                "uploadStarted": null
            },
            "size": 1838084,
            "isRemote": false,
            "remote": ""
        }
     */
    for (let i = 0, max = Object.keys(files).length; i < max; i++) {
        const f = Object.values(files)[i];
        const filename = f.data.name;

        if (f.source !== 'Webcam' && (!f.data.type || f.data.type === '')) {
            const mimeType = getMimeTypeFromFilename(filename);
            if (mimeType) {
                const msg = `resolved mime-type for: ${filename} to ${mimeType}`;
                clientLog('warn', msg, componentName);

                // Construct a new file object with the new mime type, replacing the old one.
                f.data = new File([f.data], filename, {type: mimeType});

                // Update metadata for the Uppy object.
                f.meta.type = mimeType;
                f.type = mimeType;
            } else {
                const errMsg = `unable to resolve mime-type for: ${filename}`;
                clientLog('error', errMsg, componentName);
            }
        }
    }
};

/**
 * Instanciates Uppy GUI.
 * @param config
 * @returns {Uppy}
 */
const createUppy = async (config) => {
    const maxResolution = await getMaxResolution();
    const webcamConfig = {
        modes: ['picture'],
        showVideoSourceDropdown: true,
        preferredImageMimeType: 'image/jpeg',
        videoConstraints: {
            ...maxResolution,
            facingMode: 'environment',
        },
    };
    return new Uppy({
        onBeforeUpload: onBeforeUploadHandler,
    })
        .use(DmsUppyUpload, config)
        .use(Webcam, webcamConfig);
};


/**
 * Renders the Uppy Dashboard, used when uploading files.
 * @returns {JSX.Element}
 * @constructor
 */
export const StepFileUpload = ({nextStepCallback}) => {
    const t = useFileUploadTranslation();
    const locale = useUppyTranslations();
    const [uppy, setUppy] = useState(undefined);

    const damsObjectCreatorWorkerDispatch = useDamsObjectCreatorWorkerDispatch();
    const snackbarDispatch = useSnackbarDispatch();
    const fileUploadDispatch = useFileUploadDispatch();
    const {collectionId, projectId} = useFileUploadState();

    const [uploadComplete, setUploadComplete] = useState(false);

    /**
     * Returns a list of headers used when requesting an upload-URL from DMSF.
     * @param cid   int Collection ID
     * @returns {{"x-collection-id": *, "x-quote-mode": string, "Content-Type": string}}
     */
    const createRequestHeaders = (cid) => {
        return {
            'x-collection-id': cid,
            'x-quote-mode': getXQuoteMode(),
            'Content-Type': 'application/json'
        };
    };

    /**
     * If an error occured, show a snackbar indicating that something failed.
     */
    const showError = () => {
        snackbarDispatch({
            type: ADD_MESSAGE,
            message: {
                title: t('snackBarTitleFileUpload', 'Filopplasting'),
                body: t('snackbarErrorFileUpload', 'Opplasting feilet for en eller flere filer.'),
                type: "error",
            },
        });
    };

    /**
     * Callback executed when all files have completed uploading.
     * @param res object    An object containing the upload result.
     */
    const uploadCompletedHandler = res => {
        if (res.failed.length > 0) {
            fileUploadDispatch({type: ADD_FAILED, failed: res.failed});
            showError();
        }

        const uploadedFiles = res.finished.map(f => {
            let filename = f.uppyFile.data.name;
            if (f.uppyFile.source === 'Webcam') {
                filename = f.uppyFile.name;
            }
            const objectType = getObjectTypeFromFilename(filename);
            const mimeType = getMimeTypeFromFilename(filename);
            return {
                ...f,
                objectType: objectType,
                mimeType: mimeType
            }
        });

        saveToDams(uploadedFiles);
    };

    /**
     * Creates a new "document" entry for each of the uploaded files in the DAMS database,
     * by kicking off the "creatingDamsObjects" webworker job.
     * @param files Array   The uploaded files
     */
    const saveToDams = (files) => {
        // Web worker job config, base-object.
        const apiGateway = window._env_.REACT_APP_DAMS_ADMIN_API;
        const webWorkerJob = {
            workerConfig: getWorkerConfigBase(apiGateway),
            jobName: 'creatingDamsObjects',
            collectionId: collectionId,
            files: files,
            damsObjectCreatorWorkerDispatch: damsObjectCreatorWorkerDispatch
        };

        // Kick off the web worker job.
        kickOffDamsObjectCreatorWorker({
            ...webWorkerJob
        });

        setUploadComplete(true);
    };

    /**
     * Callback triggered when the user clicks on the "Done" button after all uploads have completed.
     */
    const doneButtonClickHandler = () => {
        // Move to next step: Edit metadata.
        nextStepCallback();
    };

    /**
     * Returns the configuration object for the Uppy library, which is used for file uploads.
     *
     * @param {string} cid - The client ID used to create the request headers.
     * @return {Object} The configuration object for Uppy, including the following properties:
     *   - finishedHandler: The function to be called when the upload is finished.
     *   - orderUploadEndpoint: The endpoint for obtaining the upload URL.
     *     - url: The URL for the endpoint.
     *     - method: The HTTP method for the request.
     *     - headers: The request headers, including the client ID.
     *   - commitMultipartEndpoint: The endpoint for committing the multipart upload.
     *     - url: The URL for the endpoint.
     *     - headers: The request headers, excluding the "Content-Type" header.
     *   - chunkSize: The size of each chunk in bytes.
     *   - limit: The maximum number of concurrent uploads.
     *   - retry: The number of times to retry the upload if it fails.
     *   - mock: A flag indicating whether to use the mock upload functionality.
     */
    const getUppyConfig = (cid) => {
        const headers = createRequestHeaders(cid);

        let commitHeaders = headers;
        delete commitHeaders["Content-Type"];   // EMSF-test does not accept this content-type when committing.

        return {
            //   uploadHandler: uploadHandler,
            finishedHandler: uploadCompletedHandler,
            orderUploadEndpoint: {
                url: `${window._env_.REACT_APP_DAMS_ADMIN_API}/v2/dms/order-upload-url/`,
                method: 'POST',
                headers: headers
            },
            commitMultipartEndpoint: {
                url: `${window._env_.REACT_APP_DAMS_ADMIN_API}/v2/dms/commit-multipart/`,
                headers: commitHeaders
            },
            chunkSize: 1e8,
            limit: 4,
            retry: 0,
            mock: false
        };
    };

    /**
     * Hook used to initialize Uppy.
     */
    useEffect(() => {
        if (collectionId === -1 && projectId === -1) {
            return;
        }
        const uppyConfig = getUppyConfig(collectionId);
        createUppy(uppyConfig).then(u => setUppy(u));
    }, [collectionId])

    return (<>
        <Box>
            {
                (Boolean(collectionId > -1 || projectId > -1) && uppy) &&
                <Dashboard uppy={uppy}
                           plugins={['DmsUppyUpload', 'Webcam']}
                           inline={true}
                           proudlyDisplayPoweredByUppy={false}
                           centerSingleFile={false}
                           doneButtonHandler={doneButtonClickHandler}
                           locale={locale}
                           disabled={collectionId === -1}
                           showProgressDetails={true}
                           hideCancelButton={true}
                />
            }

        </Box>
        {uploadComplete && <DialogFilesInBackground showDialog={true}/>}
    </>);
};
