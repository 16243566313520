import React, {useEffect} from "react";
import {createRoutesFromChildren, Route, Routes, useLocation, useNavigationType} from "react-router-dom";
import {SearchProvider} from "../../search/SearchContext";
import {SearchLandingPage} from "../../search/SearchLandingPage";
import {NewFilesPage} from "../../files/NewFilesPage";
import {FileForm} from "../../files/FileForm";
import {AllProjectsRoute, FolderByIdRoute,} from "./RoutesFolders";
import {AdministrateMetadataMappingRoute, AdminReindexRoute,} from "./RoutesAdmin";
import {UserDocuments} from "../../search/UserDocuments";
import {useAuthsState} from "../../auths/authsContext";
import {InfoDialog} from "../InfoDialog";
import {ProjectProvider, useProjectState} from "../../project/projectContext";
import {CreateProjectPage} from "../../project/CreateProjectPage";
import {MyFoldersProvider} from "../../folder/MyFoldersContext";
import {DocumentProvider} from "../../documents/documentContext";
import {ProjectViewProvider} from "../../project/archeology/projectview/projectViewContext";
import {DAMSStats} from '../../admin/DAMSStats';
import * as Sentry from "@sentry/react";
import {matchRoutes} from "react-router";
import {RegenerateMetadata} from "../../admin/RegenerateMetadata";
import {SET_EXTERNAL_USER, SET_MUSEUM_USER_OR_ADMIN, useAppDispatch, useAppState} from "../AppContext";
import {ProjectView} from "../../project/ProjectView";
import {AdminProjectView} from "../../project/general/AdminProjectView";
import {FoldersProvider} from "../../folder/FoldersContext";
import {ImportData} from "../../import/ImportData";
import {FoldersList} from "../../folder/list/FoldersList";


Sentry.init({
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
    ],
    enabled: ['production', 'beta', 'development'].includes(window._env_.REACT_APP_X_QUOTE_MODE),
    tracesSampleRate: 1.0,
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const SearchLandingPageRoute = () => {
    return (
        <SearchProvider>
            <SearchLandingPage/>
        </SearchProvider>
    );
};

export const MyFilesRoute = () => {
    return (
        <SearchProvider>
            <UserDocuments/>
        </SearchProvider>
    );
};

export const NewImagesRoute = () => {
    return (
        <NewFilesPage>
            <FileForm documentType={"StillImage"}/>
        </NewFilesPage>
    );
};

export const NewVideosRoute = () => {
    return (
        <NewFilesPage>
            <FileForm documentType={"Video"}/>
        </NewFilesPage>
    );
};

export const NewDocumentsRoute = () => {
    return (
        <NewFilesPage>
            <FileForm documentType={"Misc"}/>
        </NewFilesPage>
    );
};

export const NewSoundsRoute = () => {
    return (
        <NewFilesPage>
            <FileForm documentType={"Audio"}/>
        </NewFilesPage>
    );
};

// Project-related routeds below this line
export const NewProjectRoute = () => {
    return (
        <MyFoldersProvider>
            <ProjectProvider>
                <CreateProjectPage/>
            </ProjectProvider>
        </MyFoldersProvider>
    );
};

export const ProjectRoute = () => {
    return (
        <ProjectViewProvider>
            <SearchProvider>
                <ProjectView/>
            </SearchProvider>
        </ProjectViewProvider>
    );
};

export const ProjectAdminRoute = () => {
    return (
        <FoldersProvider>
            <DocumentProvider>
                <ProjectViewProvider>
                    <AdminProjectView/>
                </ProjectViewProvider>
            </DocumentProvider>
        </FoldersProvider>
    );
};

/**
 * Component exposing the routes available to authenticated users or an external user.
 * External user: A user that has been given access via a shared folder,
 * without having been granted access to the DAMS application or a user without any association with a museum.
 * This user may be logged on via eKultur. If the user has not been given access via a shared folder, an
 * info dialog is shown, if granted access via a shared folder, the contents of the folder is shown.
 * @returns {JSX.Element}
 * @constructor
 */
export const RoutesAuthenticated = () => {
    const appDispatch = useAppDispatch();
    const {externalUser} = useAppState();
    const {userData, userIsAuthenticated} = useAuthsState();
    const {hasArcheologyModule} = useProjectState();

    useEffect(() => {
        if (!userData) {
            return;
        }

        let accessMuseumBehalf = false;
        if (userData.appAccess?.museums) {
            const museums = userData.appAccess.museums;
            for (let i = 0, max = museums.length; i < max; i++) {
                if (museums[i].applications.find(a => a.id === window._env_.REACT_APP_ID)) {
                    accessMuseumBehalf = true;
                    break;
                }
            }
        }
        appDispatch({type: SET_MUSEUM_USER_OR_ADMIN, value: accessMuseumBehalf});

        if (userData.appAccess.museums.length === 0) {
            appDispatch({type: SET_EXTERNAL_USER});
        }
    }, [userData]);

    if (!userIsAuthenticated) {
        return <></>;
    } else if (userIsAuthenticated) {
        return (
            <>
                {/*
                    Current user is not granted access to DAMS,
                    and/or is not associated with any museum, show info dialog!
                 */}
                {externalUser && (
                    <SearchProvider>
                        <InfoDialog show={externalUser}/>
                    </SearchProvider>
                )}
                <SentryRoutes>
                    {/* Show the routes available to users that is granted access to the DAMS application */}
                    {!externalUser && (
                        <>
                            <Route exact path={"/"} element={<SearchLandingPageRoute/>}/>
                            <Route path={"/search"}>
                                <Route
                                    path={":searchString"}
                                    element={<SearchLandingPageRoute/>}
                                />
                                <Route path={"/search"} element={<SearchLandingPageRoute/>}/>
                            </Route>
                            <Route exact path={"/my-files/"} element={<MyFilesRoute/>}/>
                            <Route exact path={"/new-images/"} element={<NewImagesRoute/>}/>
                            <Route exact path={"/new-videos/"} element={<NewVideosRoute/>}/>
                            <Route
                                exact
                                path={"/new-documents/"}
                                element={<NewDocumentsRoute/>}
                            />
                            <Route exact path={"/new-sounds"} element={<NewSoundsRoute/>}/>
                            {/* Folder routes */}
                            <Route exact path={"/my-folders/"} element={<FoldersList folderType={"myfolders"}/>}/>
                            <Route
                                exact
                                path={"/all-folders/"}
                                element={<FoldersList/>}
                            />
                            <Route
                                exact
                                path={"/shared-folders/"}
                                element={<FoldersList folderType={"sharedfolders"}/>}
                            />
                            {/* Admin routes */}
                            <Route exact path={"/reindex"} element={<AdminReindexRoute/>}/>
                            <Route
                                exact
                                path={"/metadata-mapping"}
                                element={<AdministrateMetadataMappingRoute/>}
                            />
                            <Route exact path={"/regeneratemetadata"}
                                   element={
                                       <DocumentProvider>
                                           <RegenerateMetadata/>
                                       </DocumentProvider>
                                   }/>
                            <Route exact path={"/import"} element={
                                <DocumentProvider>
                                    <ImportData/>
                                </DocumentProvider>
                            }/>
                            <Route exact path={"/stats"} element={<DAMSStats/>}/>
                            {/* Project routes */}
                            {hasArcheologyModule && (<>
                                <Route
                                    path={"/new-project/:projectType"}
                                    element={<NewProjectRoute/>}
                                />
                                <Route
                                    exact
                                    path={"/project/:projectId"}
                                    element={<ProjectRoute/>}
                                />
                                <Route
                                    exact
                                    path={"/project/admin/:projectId"}
                                    element={<ProjectAdminRoute/>}
                                />
                                <Route
                                    exact
                                    path={"/all-projects"}
                                    element={<AllProjectsRoute/>}
                                />
                            </>)}
                        </>
                    )}
                    {/* A single folder, must be accessible for all users, incl. external users */}
                    <Route
                        exact
                        path={"/folders/:folderId/"}
                        element={<FolderByIdRoute/>}
                    />
                </SentryRoutes>
            </>
        );
    }
};
