import {Button, Dialog, DialogContent, Typography} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Stack from "@mui/material/Stack";
import {useState} from "react";
import PropTypes from "prop-types";
import LoopIcon from '@mui/icons-material/Loop';
import {HIDE_FILEUPLOAD_DIALOG, useFileUploadDispatch} from "./fileUploadContext";

/**
 * Renders a dialog component that displays a progress icon and a message indicating that the files are being
 * processed in the background.
 *
 * When the user clicks the button, the dialog closes and the user is redirected back to the main search page.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {boolean} props.showDialog - Determines whether the dialog should be open or not.
 * @return {JSX.Element} The dialog component.
 */
const DialogFilesInBackground = ({showDialog}) => {
    const fileUploadDispatch = useFileUploadDispatch();

    const [openDialog, setOpenDialog] = useState(showDialog);

    const closeDialog = () => {
        setOpenDialog(false);
        fileUploadDispatch({type: HIDE_FILEUPLOAD_DIALOG});
    };

    return <Dialog open={openDialog}
                   disableEscapeKeyDown={true}>
        <DialogTitle>Filer behandles i bakgrunnen</DialogTitle>
        <DialogContent>
            <Stack direction={"column"} alignItems={"center"} justifyContent={"center"}>
                <LoopIcon/>
                <Typography mt={2}>
                    Du får beskjed når behandlingen er ferdig slik at du kan fullføre opplastingen.
                </Typography>
            </Stack>
        </DialogContent>
        <DialogActions sx={{
            justifyContent: 'center'
        }}>
            <Button onClick={closeDialog}>Ok</Button>
        </DialogActions>
    </Dialog>;
};

DialogFilesInBackground.propTypes = {
    showDialog: PropTypes.bool.isRequired
}

export {DialogFilesInBackground};