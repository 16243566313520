import {StepFileUpload} from "./StepFileUpload";
import {StepAddToFolder} from "./StepAddToFolder";
import {Dialog, DialogActions, DialogContent, Step, Stepper, useMediaQuery} from "@mui/material";
import StepLabel from "@mui/material/StepLabel";
import React, {useEffect, useRef, useState} from "react";
import {StepEditMetadata} from "./StepEditMetadata";
import {
    ADD_COLLECTION_ID,
    SET_ACTIVE_STEP,
    TOGGLE_OPEN_CONFIRM_DIALOG,
    useFileUploadDispatch,
    useFileUploadState,
    useFileUploadTranslation
} from "./fileUploadContext";
import {DmsProvider} from "../dms/dmsContext";
import {SelectMuseumDialog} from "../museum/SelectMuseumDialog";
import {ProjectDialog} from "./ProjectDialog";
import {usePostFolderDocument} from "../folder/usePostFolderDocument";
import {ADD_MESSAGE, useSnackbarDispatch} from "../snackbar/SnackbarContext";
import {DialogConfirmEndWizard} from "./DialogConfirmEndWizard";
import {UploadStepperActionButtons} from "./UploadStepperActionButtons";
import Box from "@mui/material/Box";
import DialogTitle from "@mui/material/DialogTitle";
import {clientLog} from "../clientLog";
import {StepExtractMetadata} from "./StepExtractMetadata";
import {DocumentProvider} from "../documents/documentContext";

/**
 * Stepper used when uploading files to DAMS.
 *
 * This stepper works in two different contexts:
 * 1. DAMS - Only collection ID is set
 * 2. DAMS projects - Both project ID and collection ID must be set.
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const UploadStepper = () => {
    const t = useFileUploadTranslation();
    const snackbarDispatch = useSnackbarDispatch();
    const fileUploadDispatch = useFileUploadDispatch();
    const {showFileUploadDialog, activeStep, collectionId, project, uploadedFiles} = useFileUploadState();
    const [steps, setSteps] = useState();

    const [projectId, setProjectId] = useState();

    clientLog('info', `File Upload - step ${activeStep}`, 'uploadstepper');

    /**
     * Hook used to save the ID of the current project in state, when operating in project context.
     */
    useEffect(() => {
        if (!project) {
            return;
        }
        setProjectId(project.id);
    }, [project]);

    // Used when uploading files to a project.
    const [postFolderDocumentResponse, postFolderDocument] = usePostFolderDocument(projectId);

    const {error, pending, success} = postFolderDocumentResponse;

    const smallScreen = useMediaQuery("(max-width: 840px)");

    const stepHeadingRef = useRef();
    const stepContainerRef = useRef();

    /**
     * If an error occured, show a snackbar indicating that something failed.
     */
    const showError = (errMsg) => {
        snackbarDispatch({
            type: ADD_MESSAGE,
            message: {
                title: t('uploadStepperFileUpload', 'Filopplasting'),
                body: errMsg,
                type: "error",
            },
        });
    };

    /**
     * Adds the uploaded files to the selected project.
     */
    const addFilesToProject = () => {
        if (!projectId) {
            showError(t('uploadStepperUnableToAddFiles', 'Klarte ikke å legge filene i prosjektet'))
        } else {
            uploadedFiles.map(f => {
                postFolderDocument({documentId: f.id});
            });
        }
    };

    /**
     * Handler triggered when the user clicks the continue button.
     */
    const handleNext = () => {
        if (!steps) {
            return;
        }

        if (projectId > -1 && activeStep + 1 === steps["length"]) {
            // We're in project mode, and the wizard has come to an end.
            // Add uploaded files to the selected project (folder).
            addFilesToProject();
        }

        if (activeStep + 1 === steps["length"]) {
            // Wizard has come to an end, display the information dialog.
            fileUploadDispatch({
                type: TOGGLE_OPEN_CONFIRM_DIALOG
            });
        } else {
            // Wizard still has more steps, continue.
            fileUploadDispatch({
                type: SET_ACTIVE_STEP,
                activeStep: activeStep + 1
            });
        }
    };

    /**
     * Hook used to redirect to the project's page after uploading is complete.
     */
    useEffect(() => {
        if (pending || uploadedFiles.length === 0) {
            return;
        }
        if (!pending && error > 0) {
            showError(t('uploadStepperUnableToAddAllFiles', 'Klarte ikke å legge alle filene i prosjektet'));
        }
    }, [error, success, pending, uploadedFiles]);

    /**
     * Steps imported to the Stepper is defined here.
     * Note: If uploading to a project, the last step is disabled.
     * @type {[{component: JSX.Element, disabled: boolean, label: string},{component: JSX.Element, disabled: boolean, label: string},{component: JSX.Element, disabled: boolean, label: string}]}
     */
    const getSteps = () => {
        let stepperSteps = [
            {
                label: t('uploadStepperExtractMetadata', 'Hente metadata'),
                component: <StepExtractMetadata nextStepCallback={handleNext}/>
            },
            {
                label: t('uploadStepperUploadFiles', 'Last opp filer'),
                component: <StepFileUpload nextStepCallback={handleNext}/>,
            }, {
                label: t('uploadStepperEditMetadata', 'Rediger metadata'),
                component: <StepEditMetadata/>,
            },
            {
                label: t('uploadStepperAddToFolder', 'Legg i mappe'),
                component: <StepAddToFolder/>,
            },
        ];

        if (projectId > -1) {
            // If adding files to a project, remove the last step as this is irrelevant.
            stepperSteps.pop();
        }
        setSteps(stepperSteps);
    };


    /**
     * Handler triggered when the user clicks on a museum in the list.
     * @param museum
     */
    const selectMuseumHandler = museum => {
        fileUploadDispatch({
            type: ADD_COLLECTION_ID,
            collectionId: museum.collectionId
        });
    };

    /**
     * Displays the select museum dialog or project dialog, depending on the context we're in.
     * @returns {JSX.Element}
     */
    const getDialog = () => {
        return !projectId
            ? <SelectMuseumDialog t={t}
                                  selectedMuseumCallback={selectMuseumHandler}
                                  collectionId={collectionId}
                                  dialogTitle={t('uploadStepperFileUpload', 'Filopplasting')}/>
            : <ProjectDialog/>;
    };

    /**
     * Hook used to get the wizard's step, as they differ depending on uploading to a project or DAMS in general.
     */
    useEffect(() => {
        getSteps();
    }, [projectId, collectionId]);


    /**
     * CSS-class used to adapt the drop-area for files being uploaded to the current screen width.
     * @returns {{alignItems: string, display: string, justifyContent: string}}
     */
    const getContainerCssClass = () => {
        if (!smallScreen) {
            return {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                paddingTop: '16px',
                height: 'calc(100% - 100px)',
                minHeight: 'calc(100% - 100px)',
            };
        }
    };

    const setStepContainerHeight = () => {
        if (stepContainerRef.current && stepHeadingRef.current) {
            const heightStepHeading = stepHeadingRef.current['clientHeight'];
            const dialogContentHeight = stepContainerRef.current['parentElement'].clientHeight;
            const stepContainerHeight = dialogContentHeight - heightStepHeading - 100;
            stepContainerRef.current['style'].height = `${stepContainerHeight}px`;
        }
    };

    /**
     * Hook used to expand the height of the container containing the current step equal to the remaining part
     * of the dialog content.
     */
    useEffect(() => {
        if (smallScreen) {
            return;
        }
        window.addEventListener('resize', setStepContainerHeight);

        return () => {
            window.removeEventListener('resize', setStepContainerHeight);
        }
    }, []);

    useEffect(() => {
        setStepContainerHeight();
    });

    return (showFileUploadDialog && steps)
        && <DocumentProvider>
            <DmsProvider>
                {getDialog()}
                <Dialog open={true}
                        PaperProps={{
                            style: {
                                minWidth: '90vw',
                                minHeight: '90vh',
                                overflow: 'hidden'
                            }
                        }}>
                    <DialogTitle sx={{backgroundColor: '#673AB7', color: 'white'}}>
                        {t('uploadStepperFileUpload', 'Filopplasting')}
                    </DialogTitle>
                    <DialogContent
                        sx={{
                            overflow: 'hidden',
                            marginTop: '16px',
                        }}>

                        {Boolean(collectionId > -1 || projectId > -1) &&
                            <>
                                <Stepper activeStep={activeStep} alternativeLabel ref={stepHeadingRef}>
                                    {
                                        steps.map((s) => (
                                            <Step key={`step_${crypto.randomUUID()}`} disabled={s.disabled}>
                                                <StepLabel>{s.label}</StepLabel>
                                            </Step>
                                        ))
                                    }
                                </Stepper>
                                <Box sx={getContainerCssClass()} ref={stepContainerRef}>
                                    {steps[activeStep].component}
                                </Box>
                            </>
                        }
                    </DialogContent>
                    <DialogActions>
                        <UploadStepperActionButtons numSteps={steps['length']} nextCallback={handleNext}/>
                    </DialogActions>
                </Dialog>
                <DialogConfirmEndWizard numSteps={steps['length']}/>
            </DmsProvider>
        </DocumentProvider>;
};