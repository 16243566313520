import React, {useEffect, useState} from "react";
import {useField} from "formik";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";

/**
 * Renders a formik select component with loading state.
 *
 * @param {Object} props - The component props.
 * @param {string} props.formikKey - The formik key for the field.
 * @param {string} props.label - The label for the select field.
 * @param {boolean} [props.fullWidth] - Whether the select field should take up the full width.
 * @param {ReactNode} props.children - The options for the select field.
 * @param {Object} [props...] - Additional props to be spread onto the select field.
 * @return {JSX.Element} The rendered formik select component.
 */
export const FormikSelect = ({
                                 formikKey,
                                 label,
                                 fullWidth,
                                 children,
                                 ...props
                             }) => {
    const [field, meta] = useField(formikKey);

    const [loading, setLoading] = useState(true);
    const [defaultValue, setDefaultValue] = useState('');

    /**
     * Hook used await the loading of the field's default value,
     * before proceeding with the rest of the component
     */
    useEffect(() => {
        setDefaultValue(field.value);
        setLoading(false);
    }, [field.value]);


    return (!loading &&
        <FormControl fullWidth={fullWidth}>
            <InputLabel id={`${field.name}-select-label`}>{label}</InputLabel>
            <Select
                id={field.name}
                labelId={`${field.name}-select-label`}
                name={field.name}
                error={meta.touched && Boolean(meta.error)}
                value={field.value}
                defaultValue={defaultValue}
                onChange={field.onChange}
                onBlur={field.onBlur}
                {...props}
            >
                {children}
            </Select>
            <FormHelperText>{meta.touched ? meta.error : ""}</FormHelperText>
        </FormControl>
    );
};
